import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
    LinearProgress,
    Chip,
    Collapse,
    IconButton,
    Card
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import axiosServices from 'utils/axios';
import useAuth from 'hooks/useAuth';

// assets
import { IconBell, IconChevronDown, IconChevronUp } from '@tabler/icons';

const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const [archiveStates, setArchiveStates] = useState([]);
    const [expandedProgress, setExpandedProgress] = useState(true);
    const [expandedCompleted, setExpandedCompleted] = useState(false);
    const anchorRef = useRef(null);
    const { user } = useAuth();

    // Get viewed notifications from LocalStorage
    const getViewedNotifications = () => {
        const storedViewed = localStorage.getItem("viewedNotifications");
        return storedViewed ? new Set(JSON.parse(storedViewed)) : new Set();
    };

    const [viewedNotifications, setViewedNotifications] = useState(getViewedNotifications());

    // Fetch archive states
    const fetchArchiveStates = async () => {
        if (!user?._id) return;
        try {
            const response = await axiosServices.get(`/archive/progress/${user._id}`);
            setArchiveStates(response.data?.data || []);
        } catch (error) {
            console.error("Error fetching archive states:", error);
        }
    };

    // Poll every 10 seconds
    useEffect(() => {
        fetchArchiveStates();
        const interval = setInterval(fetchArchiveStates, 10000);
        return () => clearInterval(interval);
    }, [user?._id]);

    // Categorizing archive states
    const queuedAndProgress = archiveStates.filter((archive) => archive.state === 'queued' || archive.state === 'progress');
    const completedAndErrored = archiveStates.filter((archive) => archive.state === 'completed' || archive.state === 'error');

    // Count only new (unviewed) notifications
    const newNotificationCount = archiveStates.filter((archive) => !viewedNotifications.has(archive._id)).length;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);

        // If opening, mark all as viewed
        if (!open) {
            const newViewed = new Set(archiveStates.map((a) => a._id));
            setViewedNotifications(newViewed);
            localStorage.setItem("viewedNotifications", JSON.stringify([...newViewed]));
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) return;
        setOpen(false);
    };

    return (
        <>
            {/* Bell Icon with Notification Badge */}
            <Box sx={{ ml: 2, mr: 3 }}>
                <Badge badgeContent={newNotificationCount} color="error" overlap="circular">
                    <Avatar
                        variant="rounded"
                        sx={{
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        ref={anchorRef}
                        onClick={handleToggle}
                    >
                        <IconBell stroke={1.5} size="20px" />
                    </Avatar>
                </Badge>
            </Box>

            {/* Popper for Notifications */}
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                sx={{ zIndex: 1200, width: '420px' }} // Spacious layout
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position="top-right" in={open} {...TransitionProps}>
                            <Paper sx={{ borderRadius: 3, p: 2, width: '100%', maxHeight: '620px', overflow: 'hidden' }}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>

                                    {/* Archive States Section Title */}
                                    <Typography variant="h5" sx={{ px: 2, py: 1, fontWeight: 'bold', textAlign: 'center', bgcolor: theme.palette.grey[200], borderRadius: 1 }}>
                                        Archive States
                                    </Typography>

                                    <Grid container direction="column" spacing={2}>

                                        {/* Queued & Progress Section */}
                                        <Grid item xs={12} sx={{ px: 2 }}>
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <Chip label={`Progress ${queuedAndProgress.length}`} sx={{ bgcolor: theme.palette.primary.main, color: '#fff', fontWeight: 'bold' }} />
                                                <IconButton onClick={() => setExpandedProgress(!expandedProgress)}>
                                                    {expandedProgress ? <IconChevronUp /> : <IconChevronDown />}
                                                </IconButton>
                                            </Stack>
                                        </Grid>

                                        <Collapse in={expandedProgress}>
                                            <PerfectScrollbar style={{ maxHeight: '250px', padding: '10px' }}>
                                                {queuedAndProgress.map((archive) => (
                                                    <Card key={archive._id} sx={{ p: 2, mb: 2, borderRadius: 2, boxShadow: 2 }}>
                                                        <Typography fontWeight="bold">
                                                            {archive.filters.jobName} - {archive.filters.application?.join(', ')}
                                                        </Typography>
                                                        <Typography color="textSecondary">
                                                            {new Date(archive.updatedAt).toLocaleString()}
                                                        </Typography>
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={archive.processedCount / archive.totalCount * 100  > 100 ? 100 : archive.processedCount / archive.totalCount * 100}
                                                                color={archive.state === 'error' ? 'error' : 'success'}
                                                                sx={{
                                                                    mt: 1,
                                                                    height: 6,
                                                                    borderRadius: 5,
                                                                }}
                                                            />
                                                            <Typography color="textSecondary">
                                                                {`${archive.processedCount}/${archive.totalCount}`}
                                                        </Typography>
                                                    </Card>
                                                ))}
                                            </PerfectScrollbar>
                                        </Collapse>

                                        {/* Completed & Errored Section */}
                                        <Grid item xs={12} sx={{ px: 2 }}>
                                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                                <Chip label={`Completed ${completedAndErrored.length}`} sx={{ bgcolor: theme.palette.success.main, color: '#fff', fontWeight: 'bold' }} />
                                                <IconButton onClick={() => setExpandedCompleted(!expandedCompleted)}>
                                                    {expandedCompleted ? <IconChevronUp /> : <IconChevronDown />}
                                                </IconButton>
                                            </Stack>
                                        </Grid>
                                        <Collapse in={expandedCompleted}>
                                            <PerfectScrollbar style={{ maxHeight: '250px', padding: '10px' }}>
                                                {completedAndErrored.map((archive) => (
                                                    <Card key={archive._id} sx={{ p: 2, mb: 2, borderRadius: 2, boxShadow: 2 }}>
                                                        <Typography fontWeight="bold">
                                                            {archive.filters.jobName} - {archive.filters.application?.join(', ')}
                                                        </Typography>
                                                        <Typography color="textSecondary">
                                                            {new Date(archive.updatedAt).toLocaleString()}
                                                        </Typography>
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={archive.processedCount / archive.totalCount * 100  > 100 ? 100 : archive.processedCount / archive.totalCount * 100}
                                                                color={archive.state === 'error' ? 'error' : 'success'}
                                                                sx={{
                                                                    mt: 1,
                                                                    height: 6,
                                                                    borderRadius: 5,
                                                                }}
                                                            />
                                                            <Typography color="textSecondary">
                                                                {`${archive.processedCount}/${archive.totalCount}`}
                                                        </Typography>
                                                    </Card>
                                                ))}
                                            </PerfectScrollbar>
                                        </Collapse>

                                    </Grid>
                                </MainCard>
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
